const Loader = function Loader() {
  return (
    <div className="loader">
      <div className="animation"></div>
      {/* <div className="description">Some fancy text here</div> */}
    </div>
  );
};

export default Loader;
