import React from "react";
import data from "../../data/projectsListData.json";

const WorkListSmall = function WorkListSmall(props) {
  function renderWorkItems() {
    const orderedData = [].concat(data).sort((a, b) => (a.id > b.id ? -1 : 1));

    const workItems = orderedData.slice(0, 8).map((project, index) => (
      <figure
        id={`work-item-${project.id}`}
        key={project.id}
        className={`project-item gallery__item  gallery__item--${index + 1}`}
      >
        <div className="project-item-overlay">
          <div className="project-item-title">
            {project.client} - {project.name}
          </div>
        </div>
        <img
          className="img-fluid gallery__img"
          src={`images/projects/${project.thumbnail}`}
          alt="project imgs"
        />
      </figure>
    ));
    console.log(workItems);
    return <div className="gallery">{workItems}</div>;
  }

  const portfolioWork = renderWorkItems();

  function showAllProjects() {
    props.viewMore("projects");
  }

  return (
    <section id="work" className="mt-12 worklist-small">
      <div className="container">
        <div className="row">
          <div className="section-title">
            <h1>Latest Work</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div>{portfolioWork}</div>
        <div className="text-center mt-8">
          <button onClick={showAllProjects}>View All Projects</button>
        </div>
      </div>
    </section>
  );
};

export default WorkListSmall;
