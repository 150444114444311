import ExperienceItem from "./ExperienceItem";

function renderExperienceList(items) {
  if (items) {
    const list = items.map((expItem) => (
      <ExperienceItem
        key={`item_${expItem.name}_${expItem.startDate}`}
        data={expItem}
      />
    ));
    return <div>{list}</div>;
  }
  return <div>nothing to show</div>;
}

const ExperienceList = function ExpList(props) {
  const items = renderExperienceList(props.data);
  return <div>{items}</div>;
};

export default ExperienceList;
