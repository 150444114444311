const NavBar = function Navbar() {
  return (
    <nav className="navbar">
      <div className="container">
        <a href="#" className="navbar-brand pt-4">
          <span>contact@</span>danielsintrao<span>.com</span>
        </a>
      </div>
    </nav>
  );
};

export default NavBar;
