import React from "react";
import data from "../../data/projectsListData.json";

const WorkListFull = function WorkListFull(props) {
  function renderWorkItems() {
    const workItems = data.map((project) => (
      <div id={`work-item-${project.id}`} key={project.id}>
        <div className="project-item col-sm-12 mb-5 mb-md-6">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-4">
                <img
                  className="img-fluid"
                  src={`images/projects/${project.thumbnail}`}
                  alt="project imgs"
                />
              </div>
              <div className="col-sm-12 col-md-8 pl-8">
                <div className="mt-7">
                  <h2>{project.name}</h2>
                  {/* <p className="client mb-0">{project.client}</p> */}
                  <p
                    dangerouslySetInnerHTML={{ __html: project.description }}
                  ></p>
                </div>

                <div className="pt-2">
                  <a
                    className="triangle"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={project.url}
                  >
                    see project
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

    return <div>{workItems}</div>;
  }
  const portfolioWork = renderWorkItems();

  function showLandingPage() {
    props.viewMore("landing");
  }

  return (
    <section id="work" className="mt-12">
      <div className="container">
        <div className="row">
          <div className="section-title">
            <h1>Portfolio</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">{portfolioWork}</div>
        <div className="text-center mt-8">
          <button onClick={showLandingPage}>Back</button>
        </div>
      </div>
    </section>
  );
};

export default WorkListFull;
