const Services = function Services() {
  return (
    <section id="services" className="mt-8 mt-md-0">
      <div className="container">
        <div className="row">
          <div className="section-title">
            <h1>Services</h1>
          </div>
        </div>
      </div>

      <div className="container mt-11">
        <div className="row">
          <div className="col-sm-12 col-md-5 d-flex justify-content-center">
            <svg
              viewBox="0 0 398 398"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="199" cy="199" r="199" fill="#C4C4C4" />
              <circle cx="199" cy="199" r="84" fill="white" />
            </svg>
          </div>
          <div className="col-sm-12 col-md-7">
            <h2>Outsystems Front End</h2>
            <div className="mt-6">
              <p>
                OutSystems is the most complete low-code platform for building
                the enterprise solutions that drive real business value.
              </p>
            </div>
            <div className="mt-4">
              <p className="quote">
                AND I KNOW HOW TO TAKE THESE PRODUCTS TO THE NEXT LEVEL
              </p>
            </div>
            <div className="mt-4">
              <p>
                Since 2016 my focus is to help developing large applications
                which are used daily by thousands of users.
              </p>
            </div>
            <div className="mt-4">
              <p>
                My goal is to build unique, user friendly and efficient
                applications providing unique experiences for users keeping
                consistency across the products.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-12 flex-column-reverse flex-md-row">
          <div className="col-sm-12 col-md-6">
            <h2>React JS</h2>
            <div className="mt-5 mt-md-6">
              <p>
                Do you need a web or mobile application tailored to meet all
                your business needs? Or just a component to render data from an
                API to integrate into an existing application?
              </p>
            </div>
            <div className="mt-4">
              <p>
                I can deliver solutions using Javascript vanilla or React JS,
                one of the most powerful client side technologies used by the
                largest companies.
              </p>
            </div>
            <div className="mt-4">
              <p className="text-uppercase font-family-heading">
                let’s create products
                <span className="quote"> people will love </span>
              </p>
            </div>
          </div>

          <div className="col-sm-12 col-md-5 d-flex justify-content-center">
            <svg
              viewBox="0 0 495 331"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.5" y="0.5" width="494" height="330" stroke="#707070" />
              <rect x="38" y="49" width="419" height="161" fill="#E0E0E0" />
              <path
                d="M452.018 235.495L422.523 286.581L393.029 235.495L452.018 235.495Z"
                fill="#EB5757"
              />
              <rect x="21" y="94" width="88" height="27" fill="#BDBDBD" />
              <rect x="21" y="134" width="88" height="27" fill="#828282" />
            </svg>
          </div>
        </div>
        <div className="row mt-12">
          <div className="col-sm-12 col-md-5 d-flex justify-content-center">
            <svg
              viewBox="0 0 433 436"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="74.5"
                y="85.5"
                width="321"
                height="321"
                stroke="#3D3C40"
              />
              <rect x="111" y="115" width="322" height="321" fill="#E5E5E5" />
              <path
                d="M189.836 63.2787L149.719 212.998L40.1169 103.396L189.836 63.2787Z"
                fill="#FF5959"
              />
            </svg>
          </div>
          <div className="col-sm-12 col-md-7 mt-8">
            <h2>UX & UI design</h2>
            <div className="mt-4">
              <p>
                Do you want to stand out from the competition and be a reference
                in the market?
              </p>
            </div>
            <div className="mt-4">
              <p>
                I execute digital product transformation for enterprise products
                seeking to improve operational agility and customer experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
