const ExperienceItem = function ExpList(experienceItem) {
  const { title, company, description, startDate, endDate } =
    experienceItem.data;

  return (
    <div className="mt-5">
      <div className="label">{title}</div>
      <p className="mb-0">{company}</p>
      <div>
        <p className="small-text">{`${startDate} ${
          endDate ? " - " : ""
        } ${endDate}`}</p>
      </div>
    </div>
  );
};

export default ExperienceItem;
