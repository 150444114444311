const Footer = function Footer() {
  return (
    <footer className="mt-12">
      <div className="container">
        <div className="row">
          <div className="section-title dark-bg">
            <h1>Contact</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <p className="mt-4">
              Let’s make <br />
              something great, just drop me an email to
            </p>
          </div>
          <div className="col-12 big-email mb-8">contact@danielsintrao.com</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
