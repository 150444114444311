import { BrowserRouter as Router, Link } from "react-router-dom";
const HeaderContent = function HeaderContent() {
  return (
    <div className="page-header-content pt-0 pt-md-10">
      <div className="container">
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-sm-12 col-lg-7 mt-5 mt-sm-0">
            <h1 className="hero">
              <span>
                Front End UI developer based in Portugal and working remotely
                around the world.
              </span>

              <div>
                Specializing in SaaS and Enterprise product development.
              </div>
            </h1>
            <div className="mt-8">
              <p className="label">Background</p>
              <div>
                <p>
                  Hey there *handshake* 👋, my name is Daniel a versatile UI
                  developer passionate and dedicated to create meaningful
                  experiences that empower people and communities.
                </p>
                <p>
                  Having started in the digital world as graphic & UI designer I
                  learned to use many tools to create content for users, always
                  with a focus on being appealing, productive and functional.
                </p>
                <p>
                  In 2013 I started exploring the world of UX & UI design
                  helping small companies to evolve their business taking into
                  account their objectives and needs, creating solutions to
                  overcome problems.{" "}
                  <div>
                    Fascinated with the result of helping businesses evolve and
                    seeing happy users in 2015 I decided to go further and
                    accept the challenge of start learning web and mobile
                    development having had the opportunity to work on fantastic
                    projects with amazing people.
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-5 flex-column d-flex align-items-md-center">
            <div className="sidebar pb-3 pb-md-0">
              <img
                className="img-fluid"
                src="./images/im-a-cool-guy.jpg"
                alt="Its me!"
              />
              <div className="pt-4 d-none d-md-inline-block">
                <Router>
                  <div className="label">Find me</div>
                  <div className="pt-2">
                    <Link
                      className="triangle"
                      target="_blank"
                      rel="noopener noreferrer"
                      to={{
                        pathname: "https://www.linkedin.com/in/danielsintrao/",
                      }}
                    >
                      linkedin
                    </Link>
                  </div>
                  <div className="pt-2">
                    <Link
                      className="triangle"
                      target="_blank"
                      rel="noopener noreferrer"
                      to={{
                        pathname: "https://twitter.com/danielsintrao",
                      }}
                    >
                      twitter
                    </Link>
                  </div>
                  {/*  <div className="pt-2">
                    <Link
                      className="triangle"
                      target="_blank"
                      rel="noopener noreferrer"
                      to={{
                        pathname: "https://github.com/daniel-sintrao",
                      }}
                    >
                      github
                    </Link>
                  </div> */}
                </Router>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderContent;
