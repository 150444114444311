import React from "react";
import "./App.scss";
import WORK_EXPERIENCE from "./data/workExperience.json";
import EDUCATION_EXPERIENCE from "./data/educationExperience.json";
import NavBar from "./components/NavBar";
import HeaderContent from "./components/header/Content";
import Services from "./components/Services";
import WorkListSmall from "./components/work/WorkListSmall";
import WorkListFull from "./components/work/WorkListFull";
import ExperienceList from "./components/experience/ExperienceList";
import Footer from "./components/Footer";
import Loader from "./components/Loader";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.setCurrentView = this.setCurrentView.bind(this);
    this.showPageLoader = this.showPageLoader.bind(this);
    this.state = { isLoading: false, currentView: "landing" };
  }

  setCurrentView(requestedView) {
    console.log("is loading stuff", this.state.isLoading);
    this.setState({
      currentView: requestedView,
    });
    this.showPageLoader();
  }

  showPageLoader() {
    console.log("🍺 Hide page loader");
    this.setState({
      isLoading: true,
    });
    this.holdLoader(1000);
  }
  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  holdLoader = async (milliseconds = 1000) => {
    await this.sleep(milliseconds);
    this.setState({
      isLoading: false,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      if (this.state.currentView === "landing") {
        return (
          <>
            <main className="p-4 p-md-0">
              <header className="page-header">
                <NavBar />
                <HeaderContent />
              </header>
              <Services />
              <WorkListSmall viewMore={this.setCurrentView} />
              <section id="experience" className="container mt-12">
                <div className="row">
                  <div className="section-title">
                    <h1>Experience</h1>
                  </div>
                  <div className="col-md-5 col-sm-12">
                    <h2>Work</h2>
                    <ExperienceList data={WORK_EXPERIENCE} />
                  </div>
                  <div className="col-md-7 col-sm-12">
                    <h2>Courses & Workshops</h2>
                    <ExperienceList data={EDUCATION_EXPERIENCE} />
                  </div>
                </div>
              </section>
            </main>
            <Footer />
          </>
        );
      } else {
        if (this.state.currentView === "projects") {
          return (
            <>
              <main className="p-4 p-md-0">
                <header className="small page-header">
                  <NavBar />
                </header>
                <WorkListFull viewMore={this.setCurrentView} />
              </main>
              <Footer />
            </>
          );
        }
      }
    }
  }
}

export default App;
